<template>
  <div class="page">
    <div>
      <div class="head">
        <img src="../../static/images/q_bg.png" class="bg" alt="">
        <h2>{{ data.title }}</h2>
        <div class="intro">
          <div class="introduce">{{ data.introduce }}</div>
          <div class="deadline" v-show="data.id">截止时间：{{ data.deadline }}</div>
        </div>
      </div>
      <!-- <div class="redPacketTips" v-show="data.send_red_packet == 1">*完成后可领取红包</div> -->
      <div class="quetionList">
        <div class="quetionList-item" v-for="(item, index) in data.quetionList" :key="index">
          <div class="quetionList-item-title" :id="'quetion_'+item.id">{{ index+1 }}、{{ item.title }} <span v-show="item.is_require == 2">(可选)</span></div>
          <div class="daAn">
            <div v-if="item.option && item.option.length">
              <!-- <div v-for="(oItem, oIndex) in item.option" :key="oIndex">
                <label for="">{{ oItem.value }}</label>
              </div> -->
              <a-radio-group v-if="item.type == 'radio'" v-model="item.value">
                <a-radio class="radioItem" v-for="(oItem, oIndex) in item.option" :key="oIndex" :value="oItem.id">
                  {{ oItem.value }}
                </a-radio>
              </a-radio-group>

              <a-checkbox-group v-if="item.type == 'checkbox'" @change="(v)=>{changeCheckbox(v, index)}">
                <div class="radioItem" v-for="(oItem, oIndex) in item.option" :key="oIndex">
                  <a-checkbox :value="oItem.id">
                    {{ oItem.value }}
                  </a-checkbox>
                </div>
              </a-checkbox-group>
            </div>
            <div v-else-if="item.type == 'textarea'">
              <a-textarea
                v-model="item.value"
                class="textarea"
                placeholder="请输入.."
                :rows="6"
              />
            </div>
            <div v-else-if="item.type == 'score'">
              <van-rate
                v-model="item.value"
                :size="20"
                :count="10"
                color="#ffd21e"
                void-icon="star"
                void-color="#eee"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- 提交 -->
      <div class="submit" v-if="data.id && decideDeadline(data.deadline) && data.status == 1">
        <button @click="submit">提交问卷</button>
      </div>
      <!-- 优惠券弹窗 -->
      <van-dialog 
          v-model="showCoupon" 
          :confirm-button-text="'查看我的卡券'"
          @confirm="goCoupon"
          title="恭喜获得卡券！"
          :before-close="onBeforeClose"
          confirm-button-color="#3690fd"
          theme="round-button"
      >
        <div style="margin: 20px 10%"><img src="../../static/images/lottery.jpg" alt="" width="100%"></div>
      </van-dialog>
      <!-- 留资弹窗 -->
      <van-dialog 
          v-model="showFrom" 
          :confirm-button-text="'提交问卷'"
          @confirm="confirmForm"
          :before-close="onBeforeClose"
          confirm-button-color="#3690fd"
          show-cancel-button
      >
        <div style="padding: 30px 10px">
          <van-cell-group :border="false">
            <van-field 
              type="number" 
              v-model="formData.phone"
              maxlength="11" 
              label="手机号" 
              label-width="50px" 
              placeholder="请输入手机号" 
              border/>
          </van-cell-group>
          <van-cell-group :border="false">
            <van-field
              v-model="formData.code"
              center
              clearable
              label-width="50px" 
              label="验证码"
              placeholder="请输入短信验证码"
            >
              <template #button>
                <van-count-down v-show="showTime" :time="time" format="ss" ref="countDown" :auto-start="false" @finish="finish">
                  <template #default="timeData">
                    <!-- <span class="block">{{ timeData.seconds  }}秒</span> -->
                    <van-button type="default" size="small">{{ timeData.seconds  }}秒</van-button>
                  </template>
                </van-count-down>
                <van-button v-show="!showTime" size="small" type="info" @click="getCode">
                  发送验证码
                </van-button>
              </template>
            </van-field>
          </van-cell-group>
        </div>
      </van-dialog>
    </div>
    <!-- 已完成提交 -->
    <div class="successBg" v-if="showSuccessBg">
      <img src="../../static/images/q_bg.png" alt="">
      <div>{{ showText }}</div>
      <!-- <div>查看我的卡券</div> -->
      <van-button v-if="data.coupon_id" @click="goCoupon" type="primary" style="margin-top: 20px">查看我的卡券</van-button>
    </div>
  </div>
</template>
<script>
import Vue from "vue"
import { Toast, Dialog, Field, Cell, CellGroup, Button, CountDown, Rate  } from 'vant'
Vue.use(Toast)
Vue.use(Dialog)
Vue.use(Field)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(Button)
Vue.use(CountDown)
Vue.use(Rate)

import { naireShow, naireSubmit } from "@/api/naire"
import { getSdkConfig } from '@/api/common'
import { getVerifyCode } from "@/api/common"
import { mapActions } from 'vuex'
import storage from 'store'
export default {
  name: "index",
  components: {
    Toast,
    Dialog,
    Field,
    Cell,
    CellGroup,
    Button,
    Rate
  },
  data() {
    return {
      showSuccessBg: false,
      url: window.location.href,
      showFrom: false,
      showTime: false,
      time: 60000,
      formData: {},
      data: {},
      showText: '已完成，感谢您的支持！',
      showCoupon: false
      // 问卷数据 end
    }
  },
  created () {
    this.naireId = this.$route.query.id
    this.cid = this.$route.query.cid || storage.get('cid')
    this.check_code = this.$route.query.check_code || storage.get('check_code')
    this.contact_id = this.$route.query.contact_id
    this.employee_id = this.$route.query.employee_id
    this.getOpenUserInfo()
    // this.getNaireShow();
  },
  methods: {
    ...mapActions(['Login']),
    getOpenUserInfo () {
      let that = this;
     
      that.Login({
        cid: that.cid
      }).then((res) => {
        if (!res.data.token) {
            let redirectUrl = '/auth/officialAccount?cid='+that.cid+'&check_code='+that.check_code+'&module=officialAccount&target=' + encodeURIComponent(that.url);
            that.$redirectAuth(redirectUrl);
        }
        this.getNaireShow();
      }).catch(res => {
        let redirectUrl = '/auth/officialAccount?cid='+that.cid+'&check_code='+that.check_code+'&module=officialAccount&target=' + encodeURIComponent(that.url);
        that.$redirectAuth(redirectUrl);
      })
    },
    goCoupon () {
      this.$router.push('/coupon')
    },
    getNaireShow () {
      if (this.naireId) {
        naireShow({
          id: this.naireId
        }).then(res => {
          this.data = res.data
          if(!res.data.naire_result_id){
            if (this.decideDeadline(res.data.deadline) && res.data.status == 1) {
              setTimeout(() => {
                document.setTitle(this.data.title)
              },1)

              // 判断奖励方式
              switch (this.data.send_type) {
                // 发放完毕停止答卷
                case 1: 
                  if (this.data.coupon_id && this.data.coupon_quantity == this.data.coupon_received) {
                    this.showText = '卡券已发放完毕，感谢您的支持！'
                  }
                  if (this.data.red_packet_amount != '0.00' && this.data.red_packet_quantity == this.data.red_packet_received) {
                    this.showText = '红包已发放完毕，感谢您的支持！'
                  }
                  this.showSuccessBg = true
                  break
                // 发放完毕不提示继续答卷
                case 2:
                  break
                // 发放完毕提示后继续答卷
                case 3:
                  if (this.data.coupon_id && this.data.coupon_quantity == this.data.coupon_received) {
                    Dialog.alert({
                      message: '卡券奖励已发放完，但您仍可正常提交表单，感谢支持！',
                      confirmButtonText: '我知道了',
                      confirmButtonColor: '#3690fd'
                    }).then(() => {
                      // on close
                    });
                  }
                  if (this.data.red_packet_amount != '0.00' && this.data.red_packet_quantity == this.data.red_packet_received) {
                    Dialog.alert({
                      message: '红包奖励已发放完，但您仍可正常提交表单，感谢支持！',
                      confirmButtonText: '我知道了',
                      confirmButtonColor: '#3690fd'
                    }).then(() => {
                      // on close
                    });
                  }
                  break
              }
            } else {
              this.showText = '问卷已过期，感谢关注'
              this.showSuccessBg = true
            }

            
          }else{
            this.showSuccessBg = true
          }
          //设置分享
          getSdkConfig({
            url: window.location.href,
            apiList: ['openLocation', 'updateAppMessageShareData', 'updateTimelineShareData','onMenuShareAppMessage', 'onMenuShareTimeline'],
          }).then(sdk => {
            this.$wx.init(sdk.data, () => {
              this.$wx.share({
                title: res.data.title,
                desc: res.data.introduce,
                imgUrl: window.location.origin + '/naire_icon.png',
                link: window.location.href
              })
            })
          })

          this.$forceUpdate()
        })
      }
    },
    changeCheckbox (v, index) {
      console.log(v)
      this.data.quetionList[index].value = v
    },
    submitHand () {
      var data = {
        naire_id: this.data.id,
      }
      var arr = []
      for (var i = 0; i < this.data.quetionList.length; i++) {
        if(this.data.quetionList[i].value){
          var question = {
            question_id: this.data.quetionList[i].id
          }
          question[this.data.quetionList[i].type == 'checkbox' || this.data.quetionList[i].type == 'radio' ? 'option' : 'value'] = this.data.quetionList[i].value

          if (this.data.quetionList[i].type == 'score') {
            data.score = this.data.quetionList[i].value
          }

          arr.push(question)
        }
        
      }
      data.answer = arr
      if(this.data.require_mobile == 1){
        data.mobile = this.formData.phone || ''
        data.verify_code = this.formData.code || ''
      }
      if (this.employee_id) {
        data.share_employee_id = this.employee_id
      }
      if (this.contact_id && this.data.limit_contact == 1) {
        data.contact_id = this.contact_id
      }
      console.log(data)
      naireSubmit(data).then(res => {
        if (res.code == 200) {
          this.showFrom = false
          this.formData = {}
          
          this.showSuccessBg = true
          if (res.data && res.data.reward_coupon_id) {
            this.showCoupon = true
          } else {
            Toast.success('提交成功！')
          }
        } else {
          // this.showFrom = false
          Toast.fail(res.msg)
        }
        
      }).catch(res => {
        console.log(res)
      })
    },
    submit () {
      console.log(this.data.quetionList)
      for (var i = 0; i < this.data.quetionList.length; i++) {
        // 必答题是否有答案 1为必选
        if (this.data.quetionList[i].is_require == 1 && !this.data.quetionList[i].value) {
          Toast('请回答问题'+ (i+1))
          document.querySelector('#quetion_'+ this.data.quetionList[i].id).scrollIntoView({behavior: "smooth"})
          return
        }
      }
      if (this.data.require_mobile == 1) {
        this.showFrom = true
      } else {
        this.submitHand()
      }
    },
    confirmForm () {
      if (this.formData.phone && (/^1[3456789]\d{9}$/.test(this.formData.phone)) && this.formData.code) {
        this.submitHand()
      } else {
        Toast('请正确填写信息')
      }
    },
    getCode () {
      if(!this.formData.phone || !(/^1[3456789]\d{9}$/.test(this.formData.phone))){
        Toast('请正确填写手机号码')
        return
      }
      this.showTime = true
      this.$refs.countDown.start()
      getVerifyCode({
        phone: this.formData.phone
      }).then(res => {
        Toast('验证码已发送')
      })
    },
    finish () {
      this.showTime = false
      this.$refs.countDown.reset()
    },
    // 阻止vant Dialog 组件点确认后自动关闭 
    onBeforeClose (action, done) {
      if(action === 'confirm'){
        return done(false)
      }else{
        done()
      }
    },
    // 判断是否截止或停用
    decideDeadline(dateStr) {
      var deadline = new Date(dateStr).getTime()
      var theDate = new Date().getTime()
      return deadline > theDate
    },
  }
}
</script>
<style scoped lang="less">
.page {
  overflow: hidden;
  // width: 100vw;
  min-height: 100vh;
  // background-color: #d1e6ff;
  background-image: linear-gradient(to right bottom , #2d89fa, #096de7);
  padding: 20px 20px 40px;

  .head {
    position: relative;
    margin-bottom: 100px;
    text-shadow: 1px 2px 1px rgba(0, 0, 0, .1);

    >.bg {
      position: absolute;
      right: -20%;
      width: 80%;
      z-index: 0;
      opacity: 0;
      animation: bg_left 1s ;
      animation-delay: .5s;
      animation-fill-mode:forwards;
    }

    @keyframes bg_left {
      0% { 
        right: -20%;
        opacity: 0;
      }
      100% { 
        right: -10%;
        opacity: .6;
      } 
    }

    h2{
      position: relative;
      z-index: 1;
      font-size: 1.4rem;
      color: #fff;
      
      margin-bottom: 6px;
    }
    .intro {
      position: relative;
      z-index: 1;
      color: #eee;

      .introduce {
        margin-bottom: 25px;
      }
      .deadline {
        font-size: 12px;
      }
    }
  }

  .redPacketTips {
    margin-bottom: 40px;
    text-align: center;
    color: #eee;
    font-size: 12px;
  }

  .quetionList {
    position: relative;
    padding: 0 10px;
    z-index: 2;
    &-item {
      margin-bottom: 25px;
      &-title {
        color: #fff;
        font-size: 1rem;
        margin-bottom: 10px;

        span {
          font-size: 12px;
          color: #eee;
        }
      }
      .daAn {
        padding: 10px 20px;
        background-color: #fff;
        border-radius: 10px;

        .radioItem {
          display: flex;
          align-items: center;
          // line-height: 30px;
          white-space: initial;
          margin: 10px 0;
        }

        .textarea, .textarea:active, .textarea:hover, .textarea:focus  {
          border: 0;
          padding: 10px 0;
          outline: none;
          box-shadow: none;
        }

      }
    }
  }

  .submit {
    padding: 0 10px;
    
    button {
      border: 0;
      border-radius: 10px;
      background-image: linear-gradient(to right bottom , #56a0fc, #2384fa);
      width: 100%;
      padding: 12px 0;
      color: #fff;
      font-size: 1.1rem;
      text-align: center;
      box-shadow: 2px 2px 8px rgba(0, 0, 0, .2);
    }
    button:active {
      background-image: linear-gradient(to right bottom , #67a9fa, #3690fd);
    }
    /deep/ .van-goods-action-button--danger {
      background: linear-gradient(to right bottom , #67a9fa, #3690fd);
    }
  }

  .successBg {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-flow: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(to right bottom , #4f9bf8, #0864d4);

    img {
      // margin-left: 20%;
      width: 80%;
      margin-bottom: 30px;
    }

    div {
      font-size: 1rem;
      color: #fff;
      text-shadow: 1px 2px 1px rgba(0, 0, 0, .1);
    }
  }
}
</style>
