import {request} from "../plugins/axios";

// 获取问卷数据
export function naireShow (params) {
  return request({
      url: '/naire/show',
      method: 'GET',
      params: params
  })
}

// 获取问卷数据
export function naireSubmit (params) {
  return request({
      url: '/naire/submit',
      method: 'POST',
      data: params
  })
}